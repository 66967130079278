/** @jsx jsx */
import { css, jsx, Link as TLink } from "theme-ui";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialIcon from "../../components/Icons/SocialIcon";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookShareCount,
  RedditShareCount,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  LinkedinIcon,
  RedditIcon,
} from "react-share";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";
// import "./SocialLinks.css";

class SocialLinks extends Component {
  render() {
    const { postNode, postPath, mobile } = this.props;
    const post = postNode.frontmatter;
    const url = urljoin(config.siteUrl, config.pathPrefix, postPath);
    const iconSize = mobile ? 36 : 48;
    const filter = (count) => (count > 0 ? count : "");
    const renderShareCount = (count) => (
      <div className="share-count">{filter(count)}</div>
    );

    return (
      <div className="social-links">
        <RedditShareButton url={url} title={post.title}>
          <SocialIcon iconClass="fab" iconName="reddit-alien" />
          <RedditShareCount url={url}>
            {(count) => renderShareCount(count)}
          </RedditShareCount>
        </RedditShareButton>
        <TwitterShareButton url={url} title={post.title}>
          <SocialIcon iconClass="fab" iconName="twitter" />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={postNode.excerpt}>
          <SocialIcon iconClass="fab" iconName="facebook-f" />
          <FacebookShareCount url={url}>
            {(count) => renderShareCount(count)}
          </FacebookShareCount>
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          title={post.title}
          description={postNode.excerpt}
        >
          <SocialIcon iconClass="fab" iconName="linkedin-in" />
        </LinkedinShareButton>
        <TelegramShareButton url={url}>
          <SocialIcon iconClass="fab" iconName="telegram-plane" />
        </TelegramShareButton>
      </div>
    );
  }
}

export default SocialLinks;

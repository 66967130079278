/** @jsx jsx */
import { jsx, Heading, Container } from "theme-ui";

import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import PostTags from "../components/Tags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
// import "./b16-tomorrow-dark.css";
// import "./post.css";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />

          <div>
            <Heading sx={{ color: "primary", fontSize: 7 }}>
              {post.title}
            </Heading>

            <div className="post-meta">
              <PostTags tags={post.tags} />
              <div sx={{ py: 2 }} />
              <SocialLinks postPath={slug} postNode={postNode} />
            </div>

            <hr />
            <br />
            <Container
              variant="styles"
              sx={{
                "& .header-link": {
                  fill: "secondary",
                },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            </Container>

            <UserInfo config={config} />
            {/* <Disqus postNode={postNode} /> */}
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      rawMarkdownBody
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;

/** @jsx jsx */
import { jsx, Heading, Link as TLink, Grid, Box, Flex } from "theme-ui";
import { Link } from "gatsby";

import React, { Component } from "react";
import _ from "lodash";
import ArraySplit from "../../components/Util/ArraySplit";

class PostTags extends Component {
  render() {
    const { tags } = this.props;
    const rendTags =
      tags &&
      tags.map((tag) => (
        <TLink
          as={Link}
          key={tag}
          to={`/posts/tags/${_.kebabCase(tag)}`}
        >
          {tag}
        </TLink>
      ));
    return (
      <div className="post-tag-container">
        {tags && <ArraySplit arr={rendTags} sep=" // " />}
      </div>
    );
  }
}

export default PostTags;
